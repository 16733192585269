<ion-header>
	<ion-toolbar color="danger">
		<ion-buttons slot="start">
			<ion-button (click)="returnToMenu()">
				<ion-icon name="chevron-back-outline"></ion-icon>
			</ion-button>
		</ion-buttons>
		<ion-title translate>Delivery data</ion-title>
	</ion-toolbar>
</ion-header>

<ion-content fullscreen>
	<ion-list data-cy="deliveryMethod" style="font-size: 20px;">
		<ion-item [attr.color]="deliveryType == 'delivery' ? 'danger' : ''" (click)="changeDeliveryType('delivery')" style="font-size: 22px;" *ngIf="hasDeliveryType('delivery') || debugMode">
			<ion-label translate><ion-icon src="assets/icon/delivery.svg" style="margin-right: 15px;"></ion-icon>Delivery</ion-label>
			<small *ngIf="restaurant.delivery_time_min > 0"><ion-icon name="time-outline" style="margin-right: 4px;"></ion-icon> {{timeConvert(restaurant.delivery_time_min)}} a {{timeConvert(restaurant.delivery_time_max)}}</small>
		</ion-item>
		<ion-item [attr.color]="deliveryType == 'balcony' ? 'danger' : ''" (click)="changeDeliveryType('balcony')" style="font-size: 22px;" *ngIf="hasDeliveryType('takeout') || debugMode">
			<ion-label translate><ion-icon src="assets/icon/store.svg" style="margin-right: 15px;"></ion-icon>Takeout</ion-label>
			<small *ngIf="restaurant.takeout_time > 0"><ion-icon name="time-outline" style="margin-right: 4px;"></ion-icon> {{timeConvert(restaurant.takeout_time)}}</small>
		</ion-item>
		<ion-item [attr.color]="deliveryType == 'carTakeout' ? 'danger' : ''" (click)="changeDeliveryType('carTakeout')" style="font-size: 22px;" *ngIf="hasDeliveryType('carTakeout') || debugMode">
				<ion-label translate><ion-icon src="assets/icon/car.svg" style="margin-right: 15px;"></ion-icon>Drive-thru</ion-label>
				<small *ngIf="restaurant.takeout_time > 0"><ion-icon name="time-outline" style="margin-right: 4px;"></ion-icon> {{timeConvert(restaurant.takeout_time)}}</small>
			</ion-item>
		<ion-item [attr.color]="deliveryType == 'table' ? 'danger' : ''" (click)="changeDeliveryType('table')" style="font-size: 22px;" *ngIf="(hasDeliveryType('table') && !this.restaurant.is_closed) || debugMode">
			<ion-label translate><ion-icon src="assets/icon/kitchen-table.svg" style="margin-right: 15px;"></ion-icon>Table</ion-label>
		</ion-item>
	</ion-list>

	<schedule-selector *ngIf="askScheduleType" [deliveryType]="deliveryType" (emitScheduleType)="changeScheduleType($event)"></schedule-selector>

	<div *ngIf="scheduleSet || !askScheduleType" class="fade-in-from-top">
		<ion-list lines="full" class="ion-no-margin pointer" *ngIf="deliveryType == 'delivery'">
			<ion-item [ngClass]="{'alert-border': restaurant.deliveryCities.length && !address.city && address.street}" style="font-size: 14px;" (click)="openAddressModal()">
				<ion-avatar slot="start"><ion-icon name="location-outline" style="font-size: 35px;"></ion-icon></ion-avatar>
				<ion-label>
					<div class="ion-text-wrap">
						<div *ngIf="searchingAddress">
							<ion-spinner class="centralize"></ion-spinner>
						</div>
						<div *ngIf="!address.street && !searchingAddress" class="m-2">
							<label data-cy="zoneSelection" style="font-size: 20px; font-weight: 800;" translate>Select Address</label>
						</div>
						<div data-cy="modifyAddress" *ngIf="address.street && !searchingAddress">
							<label translate>Deliver in</label> <br>
							<b>{{address.street}}{{address.number ? ', ' + address.number : ''}}</b> <br>
							<small>{{address.zone}}{{address.complemento ? ', ' + address.complemento : ''}}</small>
						</div>
						<small data-cy="cityError" *ngIf="restaurant.deliveryCities.length && !address.city && address.street" style="color: #eb445a;">{{ 'Select the city for delivery' | translate }}</small>
					</div>
				</ion-label>
				<ion-avatar *ngIf="!successDelivery" data-cy="delivery-address-warning-icon" slot="end" class="mr-3 mt-2" style="text-align: right;"><ion-icon name="warning-outline" size="large" color="warning"></ion-icon></ion-avatar>
				<ion-avatar slot="end" class="m-0 mt-3" style="text-align: right;"><ion-icon name="chevron-forward-outline"></ion-icon></ion-avatar>
			</ion-item>
		</ion-list>

		<form #deliveryForm="ngForm">
			<ion-grid>
				<div *ngIf="deliveryType == 'balcony' || (deliveryType == 'delivery' && address.street) || deliveryType == 'carTakeout'">
					<ion-row *ngIf="deliveryType == 'carTakeout'">
						<ion-col>
							<input normalizeInput autocomplete="off" enterkeyhint="done" name="carPlate" type="text" placeholder="{{'Car Plate' | translate}}" [(ngModel)]="user.car_plate" (ngModelChange)="disabledNextButton()">
						</ion-col>
					</ion-row>
					<ion-row *ngIf="deliveryType == 'carTakeout'">
						<ion-col>
							<input normalizeInput autocomplete="off" enterkeyhint="done" name="carModelColor" type="text" placeholder="{{'Car Model and Color' | translate}}" [(ngModel)]="user.car_model_color" (ngModelChange)="disabledNextButton()">
						</ion-col>
					</ion-row>
					<ion-row>
						<ion-col>
							<input
								data-cy="name"
								#name="ngModel"
								id="nameDeliveryInput"
								autocomplete="off"
								name="name"
								enterkeyhint="done"
								type="text"
								placeholder="{{'Name (Required)' | translate}}"
								[(ngModel)]="user.name"
								(ngModelChange)="disabledNextButton()"
								nameValidator
								normalizeInput
								[ngClass]="{'invalid-input': (name.invalid && (name.dirty || name.touched))}"
							>
							<div *ngIf="(name.invalid && (name.dirty || name.touched))">
								<ion-text class="invalid-input-text">{{'Invalid Name' | translate}}</ion-text>
							</div>
						</ion-col>
					</ion-row>
					<ion-row>
						<ion-col>
							<input enterkeyhint="done" data-cy="phone" name="phone" inputmode="tel" type="text" placeholder="{{'WhatsApp with area code (Required)' | translate}}" mask="{{phoneMask()}}" prefix="{{setPrefixMask()}}" [(ngModel)]="user.phone" (ngModelChange)="disabledNextButton()">
						</ion-col>
					</ion-row>
					<ion-row *ngIf="restaurant?.has_cpf_field">
						<ion-col>
							<input
								#cpf="ngModel"
								[required]="cpfIsMandatoryByType[deliveryType]"
								mask="000.000.000-00||00.000.000/0000-00"
								minlength="11"
								name="cpf"
								id="cpfInput"
								enterkeyhint="done"
								type="text"
								autocomplete="off"
								placeholder="{{ 'CPF ou CNPJ' | translate }} {{(cpfIsMandatoryByType[deliveryType] ? '(Required)' : '(Optional)') | translate }}"
								[(ngModel)]="user.cpf"
								(ngModelChange)="disabledNextButton()"
								cpfCnpjValidator
								[ngModelOptions]="{updateOn: 'blur'}"
								[ngClass]="{'invalid-input': (cpf.invalid && (cpf.dirty || cpf.touched) && (cpfIsMandatoryByType[deliveryType] ? 'true' : cpf.value.length > 0))}"
								data-cy="cpf"
							>
							<div *ngIf="(cpf.invalid && (cpf.dirty || cpf.touched) && (cpfIsMandatoryByType[deliveryType] ? 'true' : cpf.value.length > 0))">
								<ion-text *ngIf="cpf.value.length <= 11" class="invalid-input-text">{{'Invalid CPF' | translate}}</ion-text>
								<ion-text *ngIf="cpf.value.length > 11" class="invalid-input-text">{{'Invalid CNPJ' | translate}}</ion-text>
							</div>
						</ion-col>
					</ion-row>
					<ion-row *ngIf="restaurant?.permissions_birthday_registration && !hasBirthday">
						<ion-col>
							<input
								#birthday="ngModel"
								name="birthday"
								id="birthdayInput"
								enterkeyhint="done"
								type="text"
								onfocus="(this.type = 'date')" 
								(blur)="onBlurBirthday($event)"
								max="{{maxDate}}"
								min="{{minDate}}"
								autocomplete="off"
								placeholder="Data de Nascimento (opcional para promoções)"
								(keyup)="handleKeyUp($event)"
								[(ngModel)]="user.birthday"
								(ngModelChange)="disabledNextButton()"
								data-cy="birthday"
							>
							<div *ngIf="!birthdayValid">
								<ion-text class="invalid-input-text">Data inválida</ion-text>
							</div>
						</ion-col>
					</ion-row>
					<ion-row *ngIf="!isLoggedIn && restaurant?.has_email_field">
						<ion-col>
							<input
								#email="ngModel"
								enterkeyhint="done"
								name="email"
								type="email"
								placeholder="Email {{(restaurant?.email_is_mandatory ? '(Required)' : '(Optional)') | translate }}"
								[(ngModel)]="user.email"
								(ngModelChange)="disabledNextButton()"
								emailValidator
								normalizeInput
								[required]="restaurant?.email_is_mandatory"
								[ngClass]="{'invalid-input': (email.invalid && (email.dirty || email.touched) && (restaurant?.email_is_mandatory ? 'true' : email.value.length > 0))}"
							>
							<div *ngIf="(email.invalid && (email.dirty || email.touched) && (restaurant?.email_is_mandatory ? 'true' : email.value.length > 0))">
								<ion-text class="invalid-input-text">{{'Invalid Email' | translate}}</ion-text>
							</div>
						</ion-col>
					</ion-row>
				</div>

				<ion-grid  *ngIf="deliveryType == 'table'" data-cy="tableForm">
					<ion-row>
						<ion-col>
							<input
								data-cy="tableFormInput"
								autocomplete="off"
								enterkeyhint="done"
								name="table"
								type="text"
								placeholder="{{'Table' | translate}}"
								[(ngModel)]="table"
								(ngModelChange)="validateTableInfos()"
							>

							<ion-row data-cy="tableFormServiceFee" *ngIf="restaurant.table_service_fee > 0">
								<ion-col class="ion-text-center">
									<p class="service-fee-text text-center" translate>
										<small>{{ 'serviceFee' | translate: {fee: restaurant.table_service_fee} }}</small>
									</p>
								</ion-col>
							</ion-row>
						</ion-col>
					</ion-row>
				</ion-grid>
			</ion-grid>
		</form>
	</div>

</ion-content>

<ion-footer *ngIf="deliveryType != 'table'" style="padding: 10px; box-shadow: 0px 0px 17px rgba(0,0,0,0.3); background-color: #FFF;">
	<ion-row style="margin-bottom: -5px;">
		<ion-col size="9" class="pt-0 pb-0">
			<label translate>Subtotal</label>:
		</ion-col>

		<ion-col data-cy="subTotalValue" size="3" class="pt-0 pb-0" style="text-align: right;">
			{{shoppingCartTotal | multiCurrency}}
		</ion-col>
	</ion-row>

	<ion-row style="margin-bottom: -5px;" *ngIf="deliveryType == 'delivery' && address.street && deliveryFee >= 0">
		<ion-col attr.size-lg="(deliveryFeeDiscount > 0 || (discountType == 'flat_fee' && deliveryFeeDiscount >= 0)) ? 6 ? 9" class="pt-0 pb-0">
			<label translate>Delivery fee</label>:
		</ion-col>

		<ion-col data-cy="deliveryFeeDiscountValue" size="6" class="pt-0 pb-0" style="text-align: right;" *ngIf="deliveryFeeDiscount > 0 || (discountType == 'flat_fee' && deliveryFeeDiscount >= 0)">
            <del>{{deliveryFee | multiCurrency}}</del>
            <ion-icon style="margin-right: -2px; margin-bottom: -2px;" name="arrow-forward-outline"></ion-icon>
            {{discountType == 'flat_fee' ? (deliveryFeeDiscount | multiCurrency) : (deliveryFee - deliveryFeeDiscount) | multiCurrency}}
		</ion-col>

		<ion-col data-cy="deliveryFeeTotalValue" size="3" class="pt-0 pb-0" style="text-align: right;" *ngIf="!(deliveryFeeDiscount > 0 || (discountType == 'flat_fee' && deliveryFeeDiscount >= 0))">
			{{deliveryFee | multiCurrency}}
		</ion-col>
	</ion-row>

	<ion-row style="font-size: 19px;" class="footerButton">
		<ion-col size="3" class="mt-1">
			<label>TOTAL</label>:
		</ion-col>
		<ion-col size="4" class="mt-1" style="text-align: right;">
			<b data-cy="totalValue">{{totalValue | multiCurrency}}</b>
		</ion-col>

		<ion-col size="5" style="text-align: right;">
			<ion-button data-cy="btnNext" class="button-text" expand="block" mode="ios" color="danger" [disabled]="!allSetInformations" (click)="goToPaymentPage()" translate>NEXT <ion-icon name="chevron-forward-outline" style="margin-left: 4px;"></ion-icon> </ion-button>
		</ion-col>
	</ion-row>
</ion-footer>

<p>{{table}}</p>

<ion-footer *ngIf="deliveryType == 'table'">
    <div  [ladda]="sendingOrder" class="whatsapp-button" *ngIf="validTable" translate (click)="sendOrder()" data-cy="confirmOrderTable">
        <ion-icon name="checkmark-outline" style="margin-right: 4px;"></ion-icon> {{'CONFIRM ORDER' | translate}}
    </div>
</ion-footer>