import { Component, OnInit, ViewChild } from '@angular/core';
import { RestaurantService } from 'src/app/core';
import { ModalController } from '@ionic/angular';
import { AddressPage } from './address/address.page';
import { AddressDTO } from 'src/app/shared/model/dto/AddressDTO';
import { ShoppingCartService } from 'src/app/core/services/shoppingCart.service';
import { Restaurant } from 'src/app/shared/model/Restaurant';
import { UtilsService } from 'src/app/core/services/utils.service';
import { UserDTO } from 'src/app/shared/model/dto/UserDTO';
import { OrderService } from 'src/app/core/services/order.service';
import { NavService } from 'src/app/core/services/nav.service';
import { TranslatePipe } from '@ngx-translate/core';
import { MinValueAlertPage } from './min-value-alert/min-value-alert.page';
import { DebugModeService } from 'src/app/core/services/debugMode.service';
import { DeliveryService } from 'src/app/core/services/delivery.service';
import { SuccessfullyOrderDialogPage } from './successfully-order-dialog/successfully-order-dialog.page';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { MessageErrorOrderDialogPage } from '../payment/message-error-order-dialog/message-error-order-dialog.page';
import { SchedulePage } from './schedule/schedule.page';
import { RestaurantLocalStorageService } from "src/app/core/services/restaurantLocalStorage.service";
import { AddressLatLngPage } from './address-lat-lng/address-lat-lng.page';
import { CloudwatchMetricsService } from 'src/app/core/services/cloudwatchMetrics.service';
import { ConfirmAddressLatLngPage } from './confirm-address-lat-lng/confirm-address-lat-lng.page';
import { CartRecoverService } from 'src/app/core/services/cartRecover.service';
import Swal from 'sweetalert2';
import * as moment from 'moment';
import * as uuid from 'uuid';
import { FacebookPixelService } from 'src/app/core/services/facebookPixel.service';


declare let fbq: any
declare let gtag: any

@Component({
	selector: 'app-delivery',
	templateUrl: './delivery.page.html',
	styleUrls: ['./delivery.page.scss'],
})
export class DeliveryPage implements OnInit {
    @ViewChild(SchedulePage)
    private schedulePage: SchedulePage;

	prefixMask: String = '';

	restaurant: Restaurant;
	totalValue: any;
	shoppingCartTotal: number;
	user: UserDTO = {
		name: '',
		phone: '',
		cpf: '',
		email: '',
		birthday: '',
		car_plate: '',
		car_model_color: '',
	};
	table: string;
	debugMode: number;

	askScheduleType: boolean;
	scheduleType: string;
	scheduleSet: boolean;

	hasBirthday: boolean = false;

	minTotalDelivery: number;
	allSetInformations: boolean = false;
	deliveryType: string;
	deliveryFee: number;
	deliveryFeeID: any;
	deliveryFeeDiscount: number = 0;
	discountType: any;
	address: AddressDTO = {
		street: '',
		number: '',
		zone: '',
		complemento: '',
		address_ref: '',
		city: '',
		distance: null,
		coordinates: {
			latitude: null,
			longitude: null
		},
		deliveryFeeID: null,
	};
	carTakeout = false;
	searchingAddress = false;

	validTable: boolean = false;
	sendingOrder = false;
	isLoggedIn =  false;

	successDelivery = false;

	maxDate: any;
	minDate: any;
	birthdayValid = true;
	cpfIsMandatoryByType = {
		delivery: false,
		balcony: false,
		table: false,
		carTakeout: false,
		takeout: false,
	};


	public isCartRecover = this.cartRecoverService.isRecover;

	constructor(private deliveryService: DeliveryService,
				private restaurantService: RestaurantService,
				private cartService: ShoppingCartService,
				private orderService: OrderService,
				private navService: NavService,
				private debugModeService: DebugModeService,
				private authenticationService: AuthenticationService,
				private modalController: ModalController,
				public translatePipe: TranslatePipe,
				private utils: UtilsService,
                private shoppingCartService: ShoppingCartService,
				private restaurantLocalStorageService: RestaurantLocalStorageService,
				private cloudwatchMetrics: CloudwatchMetricsService,
				private cartRecoverService: CartRecoverService,
				private facebookPixelService: FacebookPixelService
	) { }

	ngOnInit() {
		this.restaurant = this.restaurantService.restaurant.info;
		this.shoppingCartTotal = this.cartService.total();
		this.debugMode 	= this.debugModeService.getMode();
		this.isLoggedIn = this.authenticationService.isLoggedIn();
        this.deliveryFeeDiscount = this.orderService.order.details.deliveryFeeDiscount;
		this.minTotalDelivery	 = this.restaurant.min_total_delivery
		
		this.setCpfIsMandatoryByType();
		this.parseToNewLocalStorage();
		this.validateUserCity();
		this.checkIfHasData();
		this.getUserInfo();
        this.checkIfHasOnlyOneDeliveryOption();
		this.disabledNextButton();
		this.calculateTotal();
		this.calculateMaxAndMinDate();

		if (this.cartRecoverService.uuid)
            this.cartRecoverService.updateTimestamp().subscribe();
    }

    ionViewWillEnter() {
		this.recoverCart();

		if(this.deliveryType)
			this.changeDeliveryType(this.deliveryType);
	}


    recoverCart() {
        if (!this.cartRecoverService.isRecover)
            return;

        this.cartRecoverService.updateTimestamp();

        const cart = JSON.parse(localStorage.getItem(`cart-${this.restaurant.id}`));
        if (cart.deliveryType)
            this.changeDeliveryType(cart.deliveryType);
    }

	handleKeyUp = function(event) {
		if(event.key === 'Delete' || event.key === 'Backspace') {
				this.birthdayValid = true;
		}else{
			this.dateValidator(this.user.birthday)
		}
		this.disabledNextButton()
	};	

	onBlurBirthday(event: FocusEvent) {
		const birthdayInput = event.target as HTMLInputElement;
		if (!this.user.birthday) {
				birthdayInput.type = 'text';
				this.birthdayValid = true;
		} else{
			this.dateValidator(this.user.birthday)
		}
		this.disabledNextButton()
	}

	dateValidator = (date) => {
		if(moment(date).isBetween(moment().subtract(100, 'years').format("YYYY-MM-DD"), moment().format("YYYY-MM-DD"))) {
			this.birthdayValid = true;
				return;
		}
		this.birthdayValid = false;
		return;
	}

	parseToNewLocalStorage()
	{
		let array = {};

		['reference', 'complement'].forEach(item => {
			array[item] = this.restaurantLocalStorageService.getItem(item) ?? '';
			this.restaurantLocalStorageService.removeItem(item);
		})

		if(!array["reference"] && !array["complement"]) return;

		this.restaurantLocalStorageService.setItem('address_ref', array["reference"]);
		this.restaurantLocalStorageService.setItem('complemento', array["complement"]);
	}

	validateUserCity() {
		let userCity = this.restaurantLocalStorageService.getItem('city');

		if(!userCity) return;

		let index = this.restaurant.deliveryCities.findIndex(element => element.name == userCity);

		if(index == -1) this.restaurantLocalStorageService.setItem('city', '');
	}

	getUserInfo() {
		let userInfos = ['name', 'phone', 'cpf', 'email', 'birthday'];

		userInfos.forEach(info => {
			this.user[info] = this.utils.normalizeText(this.restaurantLocalStorageService.getItem(info)) ?? '';
		})
			


		if (this.debugMode) this.user.name = this.translatePipe.transform("***TEST DONT MAKE IT***");
		if (this.user.birthday) this.hasBirthday = true;
	}

    checkIfHasOnlyOneDeliveryOption() {
        let deliveryOptions = []
		if (this.hasDeliveryType('delivery')) deliveryOptions.push('delivery');
        if (this.hasDeliveryType('takeout')) deliveryOptions.push('balcony');
        if (this.hasDeliveryType('table')) deliveryOptions.push('table');

        if (deliveryOptions.length == 1) this.changeDeliveryType(deliveryOptions[0]);
    }

	hasDeliveryType(type) {
		switch (type) {
			case 'delivery':
				if (this.restaurant.no_delivery) return false;
				if (this.restaurant.is_closed && this.restaurant.scheduleSettings.enable && this.restaurant.scheduleSettings.allowSchedulingWhileClosed) return true;
				if (!this.restaurant.scheduleSettings.enable) return this.restaurant.shift_has_delivery;
				return true;

			case 'takeout':
				if (this.restaurant.no_takeout) return false;
				if (this.restaurant.is_closed && this.restaurant.scheduleSettings.enable && this.restaurant.scheduleSettings.allowSchedulingWhileClosed) return true;
				if (!this.restaurant.scheduleSettings.enable) return this.restaurant.shift_has_takeout;
				return true;
				
			case 'table':
				if (this.restaurant.no_table) return false;
				break;

			case 'carTakeout':
				return this.restaurant.has_car_takeout;
		}
		return true;
	}

	checkIfHasData() {
		if (this.orderService.order.details.deliveryType) {
			this.deliveryType = this.orderService.order.details.deliveryType;
			this.deliveryFee = this.orderService.order.details.deliveryFee;
		}

		if (this.orderService.order.user.name) {
			this.user.name = this.orderService.order.user.name;
			this.user.phone = this.orderService.order.user.phone;
			this.user.email = this.orderService.order.user.email;
			this.user.cpf = this.orderService.order.user.cpf;
			this.user.birthday = this.orderService.order.user.birthday;
		}

		if(this.orderService.order.details.carTakeout) {
			this.user.car_plate = this.orderService.order.user.car_plate;
			this.user.car_model_color = this.orderService.order.user.car_model_color;
		}

		if(this.orderService.order.user.address) {
			this.address.city = this.orderService.order.user.city;
			this.address.street = this.orderService.order.user.address;
			this.address.number = this.orderService.order.user.number ? this.orderService.order.user.number : '';
			this.address.complemento = this.orderService.order.user.complemento;
			this.address.address_ref = this.orderService.order.user.address_ref;
			this.address.zone = this.orderService.order.user.zone;
		}

		if(this.orderService.order.details.table) {
			this.table = this.orderService.order.details.table;
		}
	}

	resetAddress() {
		this.successDelivery = false;

		let itensToReset = ['street', 'number', 'zone', 'complemento', 'address_ref', 'city', 'distance', 'zone_object', 'deliveryFeeID', 'latitude', 'longitude'];

		itensToReset.forEach(item => this.restaurantLocalStorageService.setItem(item, ''));

		this.address = {
			street: '',
			number: '',
			zone: '',
			complemento: '',
			address_ref: '',
			city: '',
			distance: null,
			deliveryFeeID: null,
			coordinates: {
				latitude: null,
				longitude: null
			}
		};
	}

	changeDeliveryType(newDeliveryType) {
		if ((newDeliveryType == 'delivery' && this.restaurant.min_total_delivery > this.cartService.total()) && !this.debugMode) {
			this.openMinValueAlert(this.restaurant.min_total_delivery);
			return;
		}

		this.carTakeout = false;
        this.deliveryType = newDeliveryType;

		this.scheduleSet = false;
		this.askScheduleType = true;
        this.scheduleType = '';

		if(this.deliveryType == 'table' || !this.restaurant.scheduleSettings.enable) {
            this.askScheduleType = false;
			this.clearScheduleData();
		}

        if (this.deliveryType == 'delivery') this.calculateDeliveryFee()
		this.orderService.setDeliveryType(this.deliveryType);

        if (this.restaurant.scheduleSettings.enable && this.schedulePage && this.schedulePage.scheduleType) {
            this.schedulePage.changeScheduleType(this.restaurant.is_closed ? 'schedule' : null);
            this.schedulePage.changeWeekday(0);
        }

		this.calculateTotal();
		this.disabledNextButton();
		this.saveDeliveryLocalStorage();
		if(this.schedulePage)
			this.schedulePage.hasShowOrderNow(newDeliveryType);
	}

	saveDeliveryLocalStorage() {
        const cartName = `cart-${this.restaurantService.restaurant.info.id}`;

        let currentCart = JSON.parse(localStorage.getItem(cartName));
        if (!currentCart)
            currentCart = {};

        currentCart.deliveryType = this.deliveryType;

        localStorage.setItem(cartName, JSON.stringify(currentCart));
    }

	getAddressFromLocalStorage(){
		let addressInfos = ['street', 'number', 'zone', 'complemento', 'address_ref', 'city', 'distance', 'deliveryFeeID', 'latitude', 'longitude'];

		for (let info of addressInfos) {
			if(info == 'distance'){
				this.address[info] = parseFloat(this.restaurantLocalStorageService.getItem(info));
				continue;
			}

			if(info == 'longitude' || info == 'latitude'){
				this.address.coordinates[info] = this.restaurantLocalStorageService.getItem(info);
				continue;
			}

			this.address[info] = this.utils.normalizeText(this.restaurantLocalStorageService.getItem(info)) ?? '';
		}

	}


	async calculateDeliveryFee(){
		if(!this.restaurantLocalStorageService.getItem('street')){
			this.resetAddress();
			return;
		}

		this.searchingAddress = true;
		this.getAddressFromLocalStorage();

		await this.deliveryService.calculateDeliveryFee(this.address).then(async result => {
			if(!result.delivery || !result.found){
				this.resetAddress();
				return
			}

			({ deliveryFee: this.deliveryFee, deliveryFeeID: this.deliveryFeeID, deliveryFeeID: this.address.deliveryFeeID } = result);
			this.successDelivery = true;
			this.restaurantLocalStorageService.setItem('deliveryFeeID', String(result.deliveryFeeID));
			this.orderService.addAddress(this.address, this.deliveryFee, this.deliveryFeeID);
			await this.calculateDeliveryFeeDiscount();
		}).catch(() => {
			this.resetAddress();
		}).finally(() => {
			this.calculateTotal();
			this.disabledNextButton();
			this.searchingAddress = false;
		});
	}

	async calculateDeliveryFeeDiscount() {
		await this.deliveryService.calculateDeliveryFeeDiscount(this.shoppingCartTotal, this.deliveryFee).then(result => {
			this.deliveryFeeDiscount = result['discountValue'];
			this.discountType = result['discountType'];
			this.orderService.order.details.deliveryFeeDiscount = result['discountValue'];
			this.orderService.order.details.discountType = result['discountType'];
		});
	}

	async openMinValueAlert(minimalValue) {
		const modal = await this.modalController.create({
			component: MinValueAlertPage,
			cssClass: 'useLoyalty-modal',
			componentProps: {
				'minimalValue': minimalValue
			}
		});
		return await modal.present();
	}

	timeConvert(minutes) {
		return this.utils.timeConvert(minutes)
	}

	phoneMask() {
		return this.utils.phoneMask();
	}

	setPrefixMask() {
		if(!this.prefixMask && this.user.phone)
			this.prefixMask = this.utils.prefixMask();

		return this.prefixMask
	}

	async newAddressModal() {
		return await this.modalController.create({
			component: AddressLatLngPage,
            componentProps: {
				'storageAddress': this.address.street ? JSON.parse(JSON.stringify(this.address)) : null
            },
        });
	}

	async oldAddressModal() {
		return await this.modalController.create({
			component: AddressPage,
			componentProps: {
				'storageAddress': this.address.street ? JSON.parse(JSON.stringify(this.address)) : null
			}
		});
	}

	checkIfHasDeliveryFeeData(data) {
		if (!data.deliveryFee || !data.deliveryFeeID) {
			return false;
		}

		return true;
	}

	async openAddressModal() {
		let modal;
		let modalInstance;

		if(this.restaurant.modules.photon_autocomplete_leaflet_pinpoint_address_page || this.restaurant.modules.mpboy){
			modalInstance = 'new';
			this.cloudwatchMetrics.new_address_dialog_started();
			modal = await this.newAddressModal();
		}else{
			modalInstance = 'old';
			this.cloudwatchMetrics.old_address_dialog_started();
			modal = await this.oldAddressModal();
		}

		modal.onDidDismiss().then(response => {
			if(!response.data || !response.data.address)
				return;

			if (response.data.deliveryFeeID) {
				this.address = response.data.address;
				this.deliveryFee = response.data.deliveryFee;
				this.discountType = response.data.discountType;
				this.deliveryFeeID = response.data.deliveryFeeID;
				this.deliveryFeeDiscount = response.data.deliveryFeeDiscount;
				this.successDelivery = response.data.successDelivery;
	
				this.orderService.addAddress(this.address, this.deliveryFee, this.deliveryFeeID);
	
				this.disabledNextButton();
				this.scrollToElement(50);
				this.calculateTotal();

				if(modalInstance == 'new'){
					this.cloudwatchMetrics.new_address_dialog_finished();
				}

				if(modalInstance == 'old'){
					this.cloudwatchMetrics.old_address_dialog_finished();
				}
			}
		});

		return await modal.present();
	}

	scrollToElement(timeout) {
		setTimeout(() => {
			const element = document.getElementById("nameDeliveryInput");
			element.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}, timeout);
	}

	saveDataInLocalStorage() {
		let infos = ['name', 'phone', 'cpf', 'email', 'birthday'];

		infos.forEach(info => {
			this.user[info] ? this.restaurantLocalStorageService.setItem(info, this.user[info]) : this.restaurantLocalStorageService.setItem(info, '');
		});
	}

	validateDeliveryInfos() {
		if(!this.successDelivery || !this.address.street || (this.minTotalDelivery > this.cartService.total()) && !this.debugMode) return false;
		if(this.restaurant.deliveryCities.length && !this.address.city) return false;
		if(this.restaurant.street_number_is_mandatory && !this.address.number) return false;

		return this.validateUserInfos();
	}

	validateTableInfos() {
		if(!this.table) return false;

		this.orderService.order.details.table = this.table
		this.validTable = true;
		return true;
	}
	validateUserInfos() {
		if(!this.birthdayValid) return false
		if((!this.user) || !this.user.name.trim() || !this.user.phone || (this.restaurant.country == 'brazil' && this.user.phone.length < 10)) return false;
		if(!(/[a-zA-Z]/.test(this.user.name))) return false;
		if(this.deliveryType === 'carTakeout' && (!this.user.car_plate || !this.user.car_model_color)) return false;
		if((this.restaurant.has_cpf_field && (this.cpfIsMandatoryByType[this.deliveryType] || this.user.cpf.length > 0) && !this.utils.validateCPFCNPJ(this.user.cpf))) return false;
		if(!this.isLoggedIn && (this.restaurant.has_email_field && (this.restaurant.email_is_mandatory || this.user.email.length > 0)) && !this.utils.validateEmail(this.user.email)) return false;
		if(this.askScheduleType && !this.scheduleSet && !this.isCartRecover) return false;

		return true;
	}

	disabledNextButton() {
		switch (this.deliveryType) {
			case 'delivery':
				this.allSetInformations = this.validateDeliveryInfos();
				break;

			case 'table':
				this.allSetInformations = this.validateTableInfos();
				break;

			case 'balcony':
				this.allSetInformations = this.validateUserInfos();
				break;
			case 'carTakeout':
				this.allSetInformations = this.validateUserInfos();
				break;

			default:
				this.allSetInformations = false;
				break;
		}

		return this.allSetInformations;
	}

	calculateTotal() {
        if (this.deliveryType != 'delivery' || !this.address.street || !this.deliveryFee){
			this.totalValue = this.shoppingCartTotal;
			return;
		}

        if (this.discountType == 'flat_fee'){
			this.totalValue = this.shoppingCartTotal + this.deliveryFeeDiscount;
			return;
		}

        this.totalValue = this.shoppingCartTotal + (this.deliveryFee - this.deliveryFeeDiscount);
	}

	calculateMaxAndMinDate() {
		this.maxDate = new Date().toISOString().split("T")[0];
		const currentDate = new Date();
		currentDate.setFullYear(currentDate.getFullYear() - 100);
		this.minDate = currentDate.toISOString().split("T")[0];
	}

	async confirmLocationLeafletMapModal() {
		return await this.modalController.create({
			component: ConfirmAddressLatLngPage,
            componentProps: {
				'storageAddress': this.address.street ? JSON.parse(JSON.stringify(this.address)) : null
            },
        });
	}

	async confirmLocationLeafletMap() {
		await Swal.fire({
			title: 'Oops..',
			icon: 'warning',
			text: "Não conseguimos localizar o seu endereço, por favor, confirme sua localização no mapa na próxima tela",
			heightAuto: false
		})

		this.cloudwatchMetrics.confirm_coordinates_dialog_started();

		let modal = await this.confirmLocationLeafletMapModal();

		modal.onDidDismiss().then(response => {
			if(!response.data || !response.data.address)
				return;

			this.cloudwatchMetrics.confirm_coordinates_dialog_finished();

			this.address = response.data.address;

			this.orderService.order.details.coordinates = this.address.coordinates;
			this.orderService.order.user.coordinates = this.address.coordinates;

			this.disabledNextButton();
			this.scrollToElement(50);
		});

		return await modal.present();
	}

	goToPaymentPage() {
		const hasMpboy = this.restaurant.modules.photon_autocomplete_leaflet_pinpoint_address_page || this.restaurant.modules.mpboy;
		const hasLatitude = this.restaurantLocalStorageService.getItem('latitude');
		const hasLongitude = this.restaurantLocalStorageService.getItem('longitude');

		if (hasMpboy && this.orderService.order.details.deliveryType == 'delivery'){
			if(!hasLatitude || !hasLongitude){
				this.cloudwatchMetrics.user_needs_to_confirm_coordinates();
				return this.confirmLocationLeafletMap();
			}else{
				this.cloudwatchMetrics.user_has_coordinates();
			}
		}

		if(!this.allSetInformations) return;

		if (this.deliveryType == 'table')
			this.orderService.setTable(this.table);

		if(this.deliveryType == 'carTakeout'){
			this.carTakeout = true;
			this.orderService.setDeliveryType('balcony');
		}

		this.orderService.addUser(this.user, this.carTakeout);
		this.saveDataInLocalStorage();
		this.navService.push('payment')
	}

	sendOrder() {
		this.orderService.order.user.name = ''
		this.orderService.order.user.phone = this.user.phone
		this.orderService.order.user.cpf = this.user.cpf
		this.orderService.order.user.email = this.user.email
		this.orderService.order.user.birthday = this.user.birthday ? `${this.user.birthday} 00:00:00` : null;
		this.orderService.order.user.coordinates = this.address.coordinates

		this.orderService.order.details.guestCheckPad = []
        this.orderService.order.details.coordinates = this.address.coordinates;

		this.sendingOrder = true

		this.callPixelInitiateCheckoutEvent(this.user);

		this.orderService.sendOrder().then((response) => {
			this.sendingOrder = false

			this.callPixelPurchaseEvent(response);

			gtag('event', 'purchase', {'value': response.totalNetValue, 'currency': this.restaurant.currency });

			this.orderService.openSuccessfullyOrderModal('Order successfully placed!', 'Your order has been received on the system. Soon you will receive it at your table.');
			this.orderService.initizalizeOrder();
			this.shoppingCartService.clearCart();
			this.navService.push(``);
		}, error => {
			if(!error.error) return;

			if (error.error == 'Already Ordered') {
				this.openMessageErrorOrderTableModal(this.translatePipe.transform('Attention! Your order has been placed previously.'), this.translatePipe.transform('The establishment has already received your order. If you want to make any changes, contact the establishment to cancel your previous order. Click the button below to follow your order.'), error)
			} else if (error.error == 'Restaurant Closed' || error.error == 'No cashier were found') {
				this.openMessageErrorOrderTableModal(this.translatePipe.transform('Closed Store!'), this.translatePipe.transform('We were unable to place your order because the store is currently closed.'))
			} else {
				this.openMessageErrorOrderTableModal(this.translatePipe.transform('Error!'), this.translatePipe.transform('An error occurred while trying to place the order. Try again.'))
			}

			this.shoppingCartService.clearCart();
			this.orderService.initizalizeOrder();
			this.navService.setRoot('');
		})
	}

	async openMessageErrorOrderTableModal(header, message, error: any = '') {
		const modal = await this.modalController.create({
			component: MessageErrorOrderDialogPage,
			componentProps: {
				header: header,
				message: message,
				error: error
			},
			backdropDismiss: false,
			cssClass: 'modal-messageErrorOrder-dialog',
		});
		return await modal.present();
	}

	async openSuccessfullyOrderModal(header, message) {
		const modal = await this.modalController.create({
			component: SuccessfullyOrderDialogPage,
			componentProps: {
				header: header,
				message: message,
			},
			backdropDismiss: false,
			cssClass: 'modal-successfullyOrder-dialog',
		});
		return await modal.present();
	}

	changeScheduleType(scheduleType: string) {
		this.scheduleSet = false;
		this.scheduleType = scheduleType;

		if(scheduleType == 'now') {
			this.scheduleSet = true;
			this.clearScheduleData();
		}


		if(!this.orderService.order.details.scheduleTo){
			this.disabledNextButton();
			return;
		}
		if(this.deliveryType == 'balcony') this.scrollToElement(50);
		this.scheduleSet = true;
		this.disabledNextButton();
	}

	clearScheduleData() {
		this.orderService.order.details.schedule = false;
		this.orderService.order.details.scheduleTo = '';
	}

	returnToMenu() {
		this.navService.navigateBackward("shopping-cart");
	}
	
	callPixelInitiateCheckoutEvent(user) {
		const eventID = uuid.v4();

		fbq('track', 'InitiateCheckout', {}, { event_id: eventID });

		if(!this.restaurant.fb_pixel_id)
			return;

		const userData = {
			'name': user.name,
			'email': user.email,
			'phone': user.phone,
		};

		try {
			this.facebookPixelService.initiateCheckoutEvent(eventID, userData).subscribe();
		} catch (error) {
			console.error('erro ao iniciar evento FB Pixel', error);
		}
	}

	callPixelPurchaseEvent(order) {
		const eventID = uuid.v4();

		fbq('track', 'Purchase', { 'value': order.totalNetValue, 'currency': this.restaurant.currency}, { 
			event_id: eventID
		});

		if(!this.restaurant.fb_pixel_id)
			return;

		const userData = {
			'value': order.totalNetValue,
			'currency': this.restaurant.currency,
			'name': order.client.name,
			'email': order.client.email,
			'phone': order.client.phone,
		};

		try {
			this.facebookPixelService.purchaseEvent(eventID, userData).subscribe();
		} catch (error) {
			console.error('erro ao enviar evento FB Pixel', error);
		}
	}

	setCpfIsMandatoryByType() {
		this.cpfIsMandatoryByType.delivery = this.restaurant.cpf_delivery_is_mandatory;
		this.cpfIsMandatoryByType.balcony = this.restaurant.cpf_balcony_is_mandatory;
		this.cpfIsMandatoryByType.carTakeout = this.restaurant.cpf_balcony_is_mandatory;
		this.cpfIsMandatoryByType.takeout = this.restaurant.cpf_balcony_is_mandatory;
	}
}
